<template>
  <div>
    <div>


      <div class="main " style="background:#fff;">
        <div class="section">
          <div class="container">
            <md-card class="bg-secondary h-100">
              <md-card-content>
                <h1 class="text-white">Assortment Manager</h1>
                <p class="text-wrap text-white">
                  Assortments can be created by manually adding products or by
                  Tag Rules.<br />
                </p>

                <v-btn color="green darken-1" @click="addDialog = true">
                  Add Assortment
                </v-btn>
              </md-card-content>
            </md-card>

            <div class="row">
              <div class="col-lg-12">
                <v-container fluid>
                  <v-row dense>
                    <v-col
                      v-for="(card, index) in cards"
                      :key="index"
                      :cols="4"
                    >
                      <v-card
                       color="primary"
                        height="200px"
                      >
                      <v-card-text>
                          <h6 class="card-category text-white">
                            Assortment
                          </h6>
                          <a href="javascript:void(0)">
                            <h3
                              class="display-2 text-white"
                              v-text="card.Name"
                            ></h3>
                          </a>
                        
                          <v-btn
                            class="md-danger md-round"
                            large
                            @click="
                              $router.push('/pim/assortment-view/' + card.id)
                            "
                          >
                             View
                          </v-btn>
                      </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  <div class="row mt-8">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalCount"
                      :per-page="limit"
                      style="margin: 0 auto;"
                    ></b-pagination>
                  </div>
                </v-container>
              </div>
            </div>
          </div>
          <v-dialog v-model="addDialog" persistent max-width="600px">
            <v-form
              v-model="addDialogformvalid"
              @submit.prevent="addAssortment"
            >
              <v-card>
                <v-card-title>
                  <span class="headline">Add New Assortment</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="addDialogform.name"
                          label="Assortment Name"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <v-row>
                          <label class="col-4 col-form-label"
                            >Add product by
                          </label>
                          <div class="col-8">
                            <b-form-select
                              id="input-horizontal"
                              v-model="option"
                              :options="options"
                            ></b-form-select>
                          </div>
                        </v-row>
                      </v-col>

                      <!-- <v-col cols="12" sm="12" md="12">
                        <v-combobox
                          v-model="addchips"
                          :items="tagNames"
                          chips
                          clearable
                          :search-input.sync="addchipsearch"
                          label="Enter Tags followed by Enter"
                          multiple
                          solo
                          @change="addchipsearch = ''"
                        >
                          <template
                            #selection="{
                              attrs,
                              item,
                              select,
                              selected,
                              parent,
                            }"
                          >
                            <v-chip
                              v-bind="attrs"
                              :input-value="selected"
                              close
                              @click="select"
                              @click:close="parent.selectItem(item)"
                            >
                              <strong>{{ item }}</strong> &nbsp;
                            </v-chip>
                          </template>
                        </v-combobox>
                      </v-col> -->

                      <!-- <v-col cols="12" sm="12" md="12">
                        <div class="form-group">
                          <label for="exampleFormControlTextarea1"
                            >Assortment Description</label
                          >
                          <textarea
                            v-model="addDialogform.description"
                            class="form-control"
                            rows="3"
                          ></textarea>
                        </div>

                        <v-card-actions>
                          <v-btn text>Main Image</v-btn>

                          <b-form-file
                            v-model="main_image"
                            :state="Boolean(main_image)"
                            placeholder="Upload Image"
                            drop-placeholder="Drop file here..."
                            accept="image/*"
                          ></b-form-file>

                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-col> -->
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="addDialogClose">
                    Close
                  </v-btn>
                  <v-btn
                    type="submit"
                    :disabled="!addDialogformvalid"
                    color="blue darken-1"
                    text
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapActions } from 'vuex'
import GetCompanyTags from '@/graphql/queries/GetCompanyTags.gql'
import CreateFullAssortment from '@/graphql/mutations/CreateFullAssortment.gql'
import AssortmentCards from '@/graphql/queries/AssortmentCards.gql'
import UploadFile from '@/graphql/mutations/UploadFile.gql'

export default {

  data: () => ({
    addchipsearch: '',
    option: 'manually',
    options: [
      { value: 'manually', text: 'Manually' },
      { value: 'rule', text: 'Rule' },
    ],
    limit: 9,
    currentPage: 1,
    totalCount: 0,
    main_image_url: '',
    main_image: null,
    // tags: [],
    addchips: [],
    rules: {
      required: (value) => !!value || 'Required.',
      min: (v) => v.length >= 8 || 'Min 8 characters',
      emailMatch: () => "The email and password you entered don't match",
    },
    addDialogformvalid: false,
    addDialog: false,
    addDialogform: {
      name: '',
      description: '',
    },
    tagHeader: [{ header: 'Select an option or create one' }],

    baseurl: process.env.VUE_APP_STRAPI_API_URL || 'http://localhost:1337',
  }),
  computed: {
    tagNames: function() {
      const allTags = this.tags ? this.tags.map((t) => t.name) : []
      return [].concat(this.tagHeader, allTags)
    },
  },
  apollo: {
    tags: {
      query: GetCompanyTags,
      variables() {
        return {
          companyId: this.$store.state.auth.user.company_relation,
        }
      },
    },
    cards: {
      query: AssortmentCards,
      variables() {
        return {
          supplier: this.$store.state.auth.user.company_relation,
          limit: this.limit,
          start: (this.currentPage - 1) * this.limit,
        }
      },
    },
  },
  created() {
    setTimeout(() => {
      this.getAssortmentTotalCount({
        params: 'supplier=' + this.$store.state.auth.user.company_relation,
      }).then((res) => {
        this.totalCount = res.data
      })
    }, 1000)
  },
  methods: {
    ...mapActions(['getAssortmentTotalCount']),
    refreshAssortments() {
      this.$apollo.queries.cards.refetch()

      this.getAssortmentTotalCount({
        params: 'supplier=' + this.$store.state.auth.user.company_relation,
      }).then((res) => {
        this.totalCount = res.data
      })
    },
    addDialogClose() {
      this.addDialogform.name = ''
      this.addDialogform.description = ''
      this.addchips = []
      this.addDialog = false
    },
    async addAssortment() {
      const newAssortment = {
        Name: this.addDialogform.name,
        description: this.addDialogform.description,
        supplier: this.$store.state.auth.user.company_relation,
        product_option: this.option,
      }
      const response = await this.$apollo.mutate({
        mutation: CreateFullAssortment,
        variables: { newAssortment, searchTags: this.addchips },
      })

      const hasImageToUpload = this.main_image instanceof File

      if (hasImageToUpload) {
        await this.$apollo.mutate({
          mutation: UploadFile,
          variables: {
            contentType: 'assortment',
            contentId: response.data.createFullAssortment.assortment.id,
            field: 'main_image',
            file: this.main_image,
          },
        })
      }
      ;(this.addDialogform.name = ''), (this.addDialogform.description = '')
      ;(this.addchips = []), (this.addDialog = false)

      Swal.fire({
        title: '',
        text: 'The assortment has been successfully created!',
        icon: 'success',
        confirmButtonClass: 'btn btn-secondary',
      })
      // refreshes tags incase a new one was created
      this.$apollo.queries.tags.refetch()

      this.refreshAssortments()
    },
    remove(item) {
      this.chips.splice(this.chips.indexOf(item), 1)
      this.chips = [...this.chips]
    },
  },
}
</script>
